import { Checkbox, MenuItem, TableCell, TableRow, TextField } from "@mui/material";
import { Tour } from "../pages/Projects";
import KebabMenu from "./KebabMenu";
import StarIcon from '@mui/icons-material/Star';

interface Props {
  tour: Tour;
  deleteTour: (id: string) => void;
  onEditTourHandler: (tour: Tour) => void;
  swapElements: (index1: number, index2: number) => void;
  index: number;
  setFavoriteTour: (id: string) => void;
}

const TourRow = (props: Props) => {
  const { tour, deleteTour, swapElements, onEditTourHandler, index, setFavoriteTour } = props;
  const { image, showOnDesktop, showOnMobile, src, subtitle, title, id } = tour;

  return (
    <TableRow key={tour.id}>
      <TableCell align="left"><img style={{ maxHeight: '100px', maxWidth: '200px' }} src={image} alt='' /></TableCell>
      <TableCell align="left">{tour.showOnFrontpage && <StarIcon />}</TableCell>
      <TableCell align='left'><TextField placeholder="Navn" disabled variant="standard" value={title}></TextField></TableCell>
      <TableCell align="left"><TextField sx={{ width: '200px' }} multiline disabled value={subtitle} variant="standard"></TextField></TableCell>
      <TableCell align="left"><TextField sx={{ width: '200px' }} multiline disabled value={src} variant="standard" ></TextField></TableCell>
      <TableCell align="left"><Checkbox disabled checked={showOnDesktop} /></TableCell>
      <TableCell align="left"><Checkbox disabled checked={showOnMobile} /></TableCell>
      <TableCell align="left">
        <KebabMenu >
          <MenuItem onClick={() => setFavoriteTour(id ?? '')}>Tilføj til forside</MenuItem>
          <MenuItem onClick={() => onEditTourHandler(tour)}>Rediger</MenuItem>
          <MenuItem onClick={() => swapElements(index, index - 1)}>Ryk op</MenuItem>
          <MenuItem onClick={() => swapElements(index + 1, index)}>Ryk ned</MenuItem>
          <MenuItem onClick={() => deleteTour(id ?? '')}>Slet</MenuItem>
        </KebabMenu>
      </TableCell>

    </TableRow>
  );
};

export default TourRow;
