// @ts-nocheck
import { useLocation } from "react-router-dom";

function Preview() {

  const location = useLocation();

  return (
    <div style={{ height: "100svh" }}>
      <iframe
        width="100%"
        height="100%"
        src={`https://vr-estate.captur3d.io/view${location.pathname}`}
        frameborder="0"
        allowFullScreen
        allow="xr-spatial-tracking"
      ></iframe>
    </div>
  );
}

export default Preview;
