import { Link } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { customSplit, scrollToPage } from "../utils";

export interface Tour {
  id?: string;
  title: string;
  subtitle: string;
  image: string;
  src: string;
  showOnMobile: boolean;
  showOnDesktop: boolean;
  showOnFrontpage: boolean;
  order: number;
}

interface Props {
  tours: Tour[];
}

function Projects(props: Props) {
  const { tours } = props;
  const isPhone = useMediaQuery('(max-width: 425px)')

  const [showAllTours, setShowAllTours] = useState(false);

  const sortedTours = useMemo(() => {
    const filteredTours = isPhone ? tours.filter(tour => tour.showOnMobile) : tours.filter(tour => tour.showOnDesktop)
    return filteredTours.sort((a, b) => a.order - b.order)

  }, [isPhone, tours])

  const visibleTours = useMemo(() => {
    if (showAllTours) {
      return sortedTours
    } else {
      return sortedTours.slice(0, 3);
    }
  }, [showAllTours, sortedTours])

  const showMoreHandler = (value: boolean) => {
    setShowAllTours(value)
    if (!value) {
      // Only scroll if there we are collapsing
      scrollToPage('projects-read-more-button', isPhone ? 800 : 1200)
    }
  }

  return (
    <>
      <div className="projects-container">
        <div className="upper-container">
          <div className="first-row">
            <h2 id="projects">Vores projekter</h2>
          </div>
          <div className="second-row">
            <h1>Udforsk vores portefølje af Virtual Tours</h1>
            <p className="text">
              Vi har dedikerede teknologiske specialister, der er mestre i 360-graders teknologi og virtuelle platforme. Vi
              sørger for, at vores virtuelle ture fungerer sømløst på alle enheder, samt skaber den mest realistiske
              oplevelse som muligt.
            </p>
          </div>
          <div className="left-container"></div>
          <div className="right-container"></div>
        </div>
        <div className="our-projects">
          {visibleTours.map((tour: Tour) => (
            <div key={tour.id} className="vr-tour">
              <Link to={`/${customSplit(tour.src)}`} target="_blank" rel="noopener noreferrer">
                <img style={{ cursor: "pointer" }} src={tour.image} width={500} alt="" />
              </Link>
              <h3>{tour.title}</h3>
              <h4>{tour.subtitle}</h4>
            </div>
          ))}
        </div>
        {sortedTours.length > 3 &&
          <div className="projects-button-container">
            <button style={{ backgroundColor: '#313131' }} onClick={() => showMoreHandler(!showAllTours)} id='projects-read-more-button' className="read-more-button">{showAllTours ? 'VIS FÆRRE' : 'VIS FLERE'}</button>
          </div>}
      </div >
    </>
  );
}
export default Projects;
