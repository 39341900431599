export const scrollToPage = (id: string, offset: number = 120) => {
  const element = document.getElementById(id);
  const bodyRect = document.body.getBoundingClientRect().top;
  const elementRect = element?.getBoundingClientRect().top;
  const elementPosition = (elementRect ?? 0) - bodyRect;
  const offsetPosition = elementPosition - offset;

  window.scrollTo({
    top: offsetPosition,
    behavior: 'smooth'
  });
};



export const customSplit = (link: string) => {
  return link.split('https://vr-estate.captur3d.io/view/')[1]
}