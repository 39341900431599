import { useEffect, useState } from "react";
import "./App.scss";
import Navigation from "./components/Navigation";
import About from "./pages/About";
import Contact from "./pages/Contact";
import FrontPage from "./pages/Frontpage";
import Proces from "./pages/Proces";
import Services from "./pages/Services";
import Footer from "./pages/Footer";
import { Route, Routes } from "react-router-dom";
import Projects, { Tour } from "./pages/Projects";
import Preview from "./pages/Preview";
import Vision from "./pages/Vision";
import Admin from "./pages/Admin";
import { getAllTours } from "./firebase";
import { customSplit } from "./utils";

function App() {
  const [tours, setTours] = useState<Tour[]>([]);

  const newWebPage = (
    <>
      <Navigation />
      <FrontPage tours={tours} />
      <Services />
      <About />
      <Projects tours={tours} />
      <Proces />
      <Vision />
      <Contact />
      <Footer />
    </>
  );

  useEffect(() => {
    getAllTours().then((data) => {
      setTours(data);
    });
  }, []);

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={newWebPage}></Route>
        <Route path="/admin" element={<Admin tours={tours} />}></Route>
        {tours.map((tour) => (
          <Route path={`/${customSplit(tour.src)}`} element={<Preview />} />
        ))}
      </Routes>
    </div>
  );
}

export default App;
